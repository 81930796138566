import React from "react";
import { Routes, Route } from "react-router-dom";
import Header from "./components/Header";
import SideNavBar from "./components/SideNavBar";

import Login from "./screen/Auth/Login";
import useAuth from "./auth/useAuth";
import Register from "./screen/Auth/Register";

import Dashboard from "./components/Dashboard";
import AddCategory from "./screen/Category/AddCategory";
import AllCategories from "./screen/Category/AllCategories";
import AllServices from "./screen/Service/AllServices";
import AllBookings from "./screen/Booking/AllBookings";
import AddService from "./screen/Service/AddService";
import ListUser from "./screen/User/ListUser";
import AllVendors from "./screen/Vendor/AllVendors";
import ApproveVendors from "./screen/Vendor/ApproveVendors";

import SendNotification from "./screen/User/SendNotification";
import Revenue from "./components/Revenue";
import VendorWiseBookings from "./screen/Booking/VendorWiseBookings";
import VendorWiseServices from "./screen/Service/VendorWiseServices";
import UpdateService from "./screen/Service/UpdateService";
import AddFeatured from "./screen/Service/AddFeatured";
import Featured from "./screen/Service/Featured";
import BookingDetails from "./screen/Booking/BookingDetails";
import UpdateBooking from "./screen/Booking/UpdateBooking";
import CreateBanner from "./screen/Banner/CreateBanner";
import ListBanners from "./screen/Banner/ListBanners";
import PrivacyPolicy from "./screen/Policies/PrivacyPolicy";
import AccountDeletion from "./screen/Policies/AccountDeletion";
import SendNotificationAll from "./screen/User/SendNotificationAll";

const HeaderComponent = () => {
  const { user } = useAuth();
  if (!user) {
    return (
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/privacypolicy" element={<PrivacyPolicy />} />
        <Route path="/accountdeletion" element={<AccountDeletion />} />
      </Routes>
    );
  }
  return (
    <div>
      <Header />
      <div id="layoutSidenav">
        <SideNavBar />
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/" element={<Dashboard />} />
          <Route path="/register" element={<Register />} />
          <Route path="/category/create" element={<AddCategory />} />
          <Route path="/banner/create" element={<CreateBanner />} />
          <Route path="/banner/list" element={<ListBanners />} />
          <Route path="/category/list" element={<AllCategories />} />
          <Route path="/service/all" element={<AllServices />} />
          <Route path="/service/create" element={<AddService />} />
          <Route path="/service/update" element={<UpdateService />} />
          <Route path="/booking/list" element={<AllBookings />} />
          <Route path="/privacypolicy" element={<PrivacyPolicy />} />
          <Route path="/booking/detail" element={<BookingDetails />} />
          <Route path="/booking/update" element={<UpdateBooking />} />
          <Route path="/users/list" element={<ListUser />} />
          <Route path="/user/notification" element={<SendNotification />} />
          <Route path="/notification/all" element={<SendNotificationAll />} />
          <Route path="/vendors/all" element={<AllVendors />} />
          <Route path="/vendor/approve" element={<ApproveVendors />} />
          <Route path="/vendor/booking" element={<VendorWiseBookings />} />
          <Route path="/vendor/services" element={<VendorWiseServices />} />
          <Route path="/revenue/list" element={<Revenue />} />
          <Route path="/service/featured" element={<Featured />} />
          <Route path="/service/add-featured" element={<AddFeatured />} />
        </Routes>
      </div>
    </div>
  );
};

export default HeaderComponent;
