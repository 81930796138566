import React, { useState, useEffect } from "react";
import {
  Button,
  MenuItem,
  Select,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  InputBase,
  IconButton,
} from "@material-ui/core";
import swal from "sweetalert";
import apiClient from "../../api/client";
import { useNavigate } from "react-router-dom";
import Paper from "@material-ui/core/Paper";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import "./AddService.css";

const intialState = {
  jobs: [],
};

const initialStates = {
  jobss: [],
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  large: {
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
  table: {
    minWidth: 650,
  },
  headerContainer: {
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(1),
  },
}));

const AddService = () => {
  const [file2, setFile2] = useState([]);
  const [cat, setCat] = useState();
  const [catId, setCatId] = useState(cat ? cat[0]?._id : "1");
  const [vendor, setVendor] = useState();
  const [vendorId, setVendorId] = useState(vendor ? vendor[0]?._id : "1");
  const [coords, setCoords] = useState({
    latitude: 9.005401,
    longitude: 38.763611,
  });
  const [jobsData, setJobsData] = useState(intialState);
  const [jobsDataAm, setJobsDataAm] = useState(initialStates);
  const navigate = useNavigate();
  const classes = useStyles();

  const [state, setState] = useState({
    name: "",
    nameAm: "",
    city: "",
    cityAm: "",
    image: "",
    description: "",
    descriptionAm: "",
    size: "",
    color: "",
    colorAm: "",
    brand: "",
    brandAm: "",
    details: [],
    detailsAm: [],
    sell_price: "",
    discount: "",
    notes: "",
  });

  useEffect(() => {
    fetchCategories();
    fetchVendors();
  }, []);

  const fetchVendors = async () => {
    const { data } = await apiClient.get("/vendor");
    setVendor(data);
  };

  const fetchCategories = async () => {
    const { data } = await apiClient.get("/category/all");
    setCat(data);
  };

  const uploadFileHandler = async (e) => {
    setFile2([...file2, ...e.target.files]);
  };

  function handleChange(event) {
    const { name, value } = event.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  }

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (file2.length < 1) {
      swal("Please select image");
    } else {
      swal({
        title: "Are you sure?",
        text: "You want to Add New Product",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then(async (success) => {
        if (success) {
          if (file2) {
            const formData = new FormData();

            for (const file of file2) {
              formData.append("image", file, file.name);
            }

            const config = {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            };
            const { data } = await apiClient.post(
              "/uploadImages/uploadImages",
              formData,
              config
            );

            if (data) {
              const result1 = await apiClient.post("/service/create", {
                name: state.name,
                nameAm: state.nameAm,
                image: data,
                color: state.color,
                colorAm: state.colorAm,
                brand: state.brand,
                brandAm: state.brandAm,
                category: catId,
                vendor: vendorId,
                size: state.size,
                city: state.city,
                cityAm: state.cityAm,
                sell_price: Number(state.sell_price),
                discount: Number(state.discount),
                notes: state.notes,
                description: state.description,
                descriptionAm: state.descriptionAm,
                details: jobsData.jobs,
                detailsAm: jobsDataAm.jobss,
                location: coords,
              });

              if (result1.ok) {
                setState({
                  name: "",
                  nameAm: "",
                  city: "",
                  cityAm: "",
                  image: "",
                  description: "",
                  descriptionAm: "",
                  color: "",
                  colorAm: "",
                  brand: "",
                  brandAm: "",
                  size: "",
                  details: [],
                  detailsAm: [],
                  sell_price: "",
                  discount: "",
                  notes: "",
                });
                setFile2([]);
                swal("Success Service added");
                navigate("/service/all");
              } else {
                swal(
                  "Service Upload failed due to network issue or missing fields"
                );
              }
            } else {
              swal("Service upload Failed. Please Try again!!!!!");
              navigate("/service/all");
            }
          }
        }
      });
    }
  };

  const handleChange2 = (index, e) => {
    const values = [...jobsData.jobs];
    values[index] = e.target.value;
    setJobsData({ ...jobsData, jobs: values });
  };

  const handleChange3 = (index, e) => {
    const values = [...jobsDataAm.jobss];
    values[index] = e.target.value;
    setJobsDataAm({ ...jobsDataAm, jobss: values });
  };

  const handleAddField = (e) => {
    e.preventDefault();
    setJobsData((prevState) => ({
      ...prevState,
      jobs: [...prevState.jobs, ""],
    }));
  };
  const handleAddFields = (e) => {
    e.preventDefault();

    setJobsDataAm((prevState) => ({
      ...prevState,
      jobss: [...prevState.jobss, ""],
    }));
  };

  const handleRemoveField = (index) => {
    const updatedJobs = [...jobsData.jobs];
    updatedJobs.splice(index, 1);
    setJobsData({ ...jobsData, jobs: updatedJobs });
  };
  const handleRemoveFieldAm = (index) => {
    const updatedJobsAm = [...jobsDataAm.jobss];

    updatedJobsAm.splice(index, 1);
    setJobsDataAm({ ...jobsDataAm, jobss: updatedJobsAm });
  };

  return (
    <div id="layoutSidenav_content">
      <main>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-5 col-md-9 col-lg-6">
              <h2 className="mt-30 page-title">Services</h2>
            </div>
            <div className="col-lg-5 col-md-3 col-lg-6 back-btn">
              <Button
                variant="contained"
                onClick={() => navigate("/service/all")}
              >
                <i className="fas fa-arrow-left" /> Back
              </Button>
            </div>
          </div>
          <ol className="breadcrumb mb-30">
            <li className="breadcrumb-item">
              <a href="/">Dashboard</a>
            </li>
            <li className="breadcrumb-item">
              <a href="/admin/product/create">Services</a>
            </li>
            <li className="breadcrumb-item active">Add Service</li>
          </ol>

          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="card card-static-2 mb-30">
                <div className="card-title-2">
                  <h4>Add New Service</h4>
                </div>
                <div className="card-body-table">
                  <div className="news-content-right pd-20">
                    <div className="row">
                      <div className="col-lg-3 col-md-2">
                        <div className="form-group">
                          <label className="form-label">Service Title*</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Service Name"
                            name="name"
                            value={state.name}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-2">
                        <div className="form-group">
                          <label className="form-label">
                            Service Title (Amharic)*
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Service Name (Amharic)"
                            name="nameAm"
                            value={state.nameAm}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>
                    <br />
                    <div className="row">
                      <div className="col-lg-2 col-md-2">
                        <div className="form-group">
                          <label className="form-label">
                            Service Category*
                          </label>

                          <Select
                            className="form-control"
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={catId}
                            label="Category"
                            onChange={(e) => setCatId(e.target.value)}
                          >
                            {cat?.map((item, index) => {
                              return (
                                <MenuItem value={item._id}>
                                  {item.name}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-2">
                        <div className="form-group">
                          <label className="form-label">
                            Vendors*
                          </label>
                          <Select
                            className="form-control"
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={vendorId}
                            label="Sub-Category"
                            onChange={(e) => setVendorId(e.target.value)}
                          >
                            {vendor?.map((item, index) => {
                              return (
                                <MenuItem value={item._id}>
                                  {item.name}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </div>
                      </div>
                    </div>
                    <br />
                    <div className="row">
                      <div className="col-lg-2 col-md-2 mt-3">
                        <div className="form-group">
                          <label className="form-label">City*</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="City"
                            name="city"
                            value={state.city}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-lg-2 col-md-2 mt-3">
                        <div className="form-group">
                          <label className="form-label">City (Amharic)*</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="City (Amharic)"
                            name="cityAm"
                            value={state.cityAm}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-lg-2 col-md-2 mt-3">
                        <div className="form-group">
                          <label className="form-label">Size*</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Size"
                            name="size"
                            value={state.size}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-lg-2 col-md-2 mt-3">
                        <div className="form-group">
                          <label className="form-label">Color*</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Color"
                            name="color"
                            value={state.color}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-lg-2 col-md-2 mt-3">
                        <div className="form-group">
                          <label className="form-label">Color (Amharic)*</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Color (Amharic)"
                            name="colorAm"
                            value={state.colorAm}
                            onChange={handleChange}
                          />
                        </div>
                      </div>

                      <div className="col-lg-2 col-md-2 mt-3">
                        <div className="form-group">
                          <label className="form-label">Brand*</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Brand"
                            name="brand"
                            value={state.brand}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-lg-2 col-md-2 mt-3">
                        <div className="form-group">
                          <label className="form-label">Brand (Amharic)*</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Brand (Amharic)"
                            name="brandAm"
                            value={state.brandAm}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-lg-2 col-md-2 mt-3">
                        <div className="form-group">
                          <label className="form-label">Sell Price*</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Sell Price"
                            name="sell_price"
                            value={state.sell_price}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-lg-2 col-md-2 mt-3">
                        <div className="form-group">
                          <label className="form-label">Discount*</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Discount"
                            name="discount"
                            value={state.discount}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-lg-2 col-md-2 mt-3">
                        <div className="form-group">
                          <label className="form-label">Notes*</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Notes"
                            name="notes"
                            value={state.notes}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-12 mt-3">
                        <div className="form-group">
                          <label className="form-label">Description*</label>
                          <textarea
                            className="form-control"
                            placeholder="Description"
                            name="description"
                            value={state.description}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-12 mt-3">
                        <div className="form-group">
                          <label className="form-label">
                            Description (Amharic)*
                          </label>
                          <textarea
                            className="form-control"
                            placeholder="Description (Amharic)"
                            name="descriptionAm"
                            value={state.descriptionAm}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-12 mt-3">
                        <div className="form-group">
                          <label className="form-label">Details*</label>
                          {jobsData.jobs.map((field, index) => (
                            <div key={index} className="d-flex mb-2">
                              <input
                                type="text"
                                value={field}
                                onChange={(e) => handleChange2(index, e)}
                                placeholder={`Detail ${index + 1}`}
                                className="form-control me-2"
                              />
                              <IconButton
                                onClick={() => handleRemoveField(index)}
                                color="secondary"
                              >
                                <DeleteOutlinedIcon />
                              </IconButton>
                            </div>
                          ))}
                        </div>
                        <div>
                          <Button onClick={handleAddField} variant="contained">
                            Add Detail
                          </Button>
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-12 mt-3">
                        <div className="form-group">
                          <label className="form-label">
                            Details (Amharic)*
                          </label>
                          {jobsDataAm.jobss.map((field, index) => (
                            <div key={index} className="d-flex mb-2">
                              <input
                                type="text"
                                value={field}
                                onChange={(e) => handleChange3(index, e)}
                                placeholder={`Detail ${index + 1}`}
                                className="form-control me-2"
                              />
                              <IconButton
                                onClick={() => handleRemoveFieldAm(index)}
                                color="secondary"
                              >
                                <DeleteOutlinedIcon />
                              </IconButton>
                            </div>
                          ))}
                        </div>
                        <div>
                          <Button onClick={handleAddFields} variant="contained">
                            Add Detail (Amharic)
                          </Button>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12 col-md-12 mt-3">
                        <div className="form-group">
                          <label className="form-label">Upload Images</label>
                          <input
                            type="file"
                            onChange={uploadFileHandler}
                            multiple
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row mt-4">
                      <div className="col-lg-12 col-md-12">
                        <Button
                          onClick={handleSubmit}
                          variant="contained"
                          color="primary"
                        >
                          Add Service
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default AddService;
