import React from "react";
import Footer from "../../components/Footer";
import "./AboutUs.css";

const PrivacyPolicy = () => {
  return (
    <div
      style={{
        marginTop: "170px",
      }}
    >
      <h2 style={{ textAlign: "center", paddingBottom: "70px" }}>
        Privacy Policy for “Shamila”
      </h2>
      <div className="aboutus-content">
        <p style={{ width: "100%", margin: "0 auto", paddingBottom: "15px" }}>
          At MMH Technologies, the creator of the Shamila application, we hold
          the privacy and security of our users in high regard. This Privacy
          Policy details how we gather, use, and disclose information when you
          use our mobile application. By using the Shamila app, you consent to
          the collection and utilization of data as elucidated in this policy.
          <br />
          <br />
          <h4>Information we collect</h4>
          <p>
            We shall be clear on the information we obtain and the purpose for
            which we require your personal information. When you communicate
            with us directly, we might receive supplementary details like your
            name, email address, phone number, the content, and attachments
            enclosed in the message you send us, among other information you may
            provide. When you create an account, we may request for contact
            information, comprising details such as name, company name, address,
            email address, TIN, and telephone number.
          </p>
          <br />
          <br />
          <h4>How we use your information</h4>
          <p>
            We deploy the information assessed in various ways such as: -
            Delivering, operating, and maintaining our mobile application -
            Enhancing, personalizing, and amplifying our mobile app -
            Comprehending and analyzing how you operate our mobile app -
            Creating new products, services, features, and functionality -
            Corresponding with you, whether directly or through proxies like
            customer care, providing you with updates and other information
            relevant to the mobile app, and for marketing and promotional
            purposes - Sending you emails - Identifying and averting fraud
          </p>
          <br />
          <br />
          <h4>Partner Advertising Privacy Policies</h4>
          <p>
            You can find a list of the Privacy Policy for each of the
            advertising partners/service providers of "Shamila" through the
            website. Third-party ad servers or ad networks use technologies like
            cookies, JavaScript, or Web Beacons that are used in their
            respective advertisements and links that are dispatched directly to
            users' browsers. Once this occurs, they automatically receive your
            IP address. These technologies gauge the effectiveness of
            advertising campaigns and/or personalize the advertising content
            based on websites that you visit. "Shamila" has no access or control
            over these cookies that are used by third-party advertisers.
          </p>
          <br />
          <br />
          <h4>Third-Party Privacy Policies</h4>
          <p>
            The "Shamila" Privacy Policy does not extend to other advertisers or
            their websites/mobile apps. We recommend that you peruse the
            respective Privacy Policies of these third-party ad servers for
            in-depth information relating to their practices. It may encompass
            guidelines and instructions about opting out of specific choices.
            You can disable cookies through your individual browser preferences.
            For specific information about cookie management, consult the
            respective browser websites. You may request for businesses to
            delete collected personal data about a consumer that the business
            has acquired. Also, the owner of the information can request that a
            business/service provider, which sells customers' personal data,
            halt the sale of the customer's personal information.
          </p>
          <br />
          <br />
          <h4>"Shamila" Customer Terms and Conditions</h4>
          <p>
            By subscribing as a "Shamila" customer, you accept the following
            Terms and Conditions: - I am aged 13 years or more/ I have received
            consent from my parent/guardian at the point of registration. -
            "Shamila" is available solely through the "Shamila" mobile apps. -
            Upon the request of a registered "Shamila" service provider, I shall
            provide my original ID. - All prices/rates by registered "Shamila"
            service providers are liable to change with no forewarning or
            notice. - "Shamila" reserves the right to discontinue or amend its
            services, the "For the Customer" Terms of Use, Privacy Policy, and
            Terms and Conditions at any time without notifying the customer. -
            Services payments must be made directly to registered "Shamila"
            service providers only. - Booking, service, experience, order,
            communication, or financial transactions connected with registered
            "Shamila" service providers are not the responsibility of "Shamila".
            - A customer can only access "Shamila" mobile apps while connected
            to WIFI/Internet data. - Upgrades of registered email address, phone
            number, login specifics, or any other personal information that
            "Shamila" requires are solely the customer's responsibility.
          </p>
          <br />
          <br />
          <p>
            - "Shamila" has the right to close the Shamila division at any given
            time without prior warning or approval. - Customers must utilize
            discounts/offer within the expiration date stated on the discount. -
            "Shamila" customers are not authorized to hold "Shamila" legally
            responsible for any dispute or interaction with registered "Shamila"
            service providers. - In case of suspected fraud, error, and
            inconsistencies, "Shamila" customers must report via email to:
            shamila@mmhtechnologies.et "Shamila" Service Provider Terms and
            Conditions By registering as a "Shamila" service provider, you
            accept the following Terms and Conditions: - Service providers must
            register to "Shamila" and submit the "Shamila" Service Provider
            Agreement via "Shamila" mobile applications only. - When a
            registered "Shamila" service provider wants to withdraw from the
            "Shamila" Service Provider Agreement, the service provider must send
            a month's notice period requesting withdrawal via email to
            shamila@mmhtechnologies.et
          </p>
          <br />
          <br />
          <p>
            - Service providers must upgrade registered email addresses, phone
            numbers, login details, TIN numbers, or any other personal
            information that "Shamila" requires during registration. - "Shamila"
            reserves the right to discontinue or amend its services, Terms of
            Use, Privacy Policy, and Terms & Conditions "For Service Providers"
            at any given time without prior approval from registered service
            provider or prior notification. Alert notifications shall be sent
            via "Shamila" mobile applications notifying service providers of any
            change. - Service providers must comply with "Shamila" customers'
            agreed discount/offer as stipulated on "Shamila" mobile
            applications. - Service providers cannot modify the discount/offer
            after submission until the discount/offer expiration date. - Service
            providers are not entitled to any share in "Shamila" sales, business
            profits, gains, or loss. - All registrations, submissions,
            discounts/offers as a service provider with "Shamila" must undergo
            approval. - "Shamila" has the right to refuse/reject registration or
            service provider's services without giving a reason or explanation.
          </p>
          <br />
          <br />
          <p>
            - Service providers are not authorized to hold "Shamila" legally
            responsible for any dispute or interaction with the customers. -
            Registered "Shamila" service providers permit the use of all
            platforms for promotion, advertising, and marketing done by
            "Shamila" for the service provider. - In case of closure/withdrawal
            of a registered service provider, all services/discounts/offers
            shall be considered null and void. "Shamila" does not take legal
            action against the service provider. - Registered service providers
            must be connected to WIFI/Internet data to access "Shamila" mobile
            apps. - All payment transactions for services must be received by
            registered "Shamila" service providers exclusively. - Booking,
            service, experience, order, communication, or financial transactions
            with registered "Shamila" service providers are not the
            responsibility of "Shamila." - In case of fraud, error, and
            inconsistencies, the service provider must report via email to the
            following address; shamila@mmhtechnologies.et
          </p>
        </p>
      </div>

      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
